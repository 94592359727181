<template>
  <div>
    <Sidebar />
    <NavBar current="Add Video" :link="$paths.university.list" linkName="Resources" />
    <Container col="col-12 col-lg-10 col-xl-8">
      <div class="header mt-md-4">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title">Add Video</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label">Title</label>
          <input
            type="text"
            v-model.trim="$v.form.title.$model"
            :class="{ 'is-invalid': $v.form.title.$error }"
            placeholder="Name"
            class="form-control"
          />
          <div class="invalid-feedback">
            <small>Please fill in this field correctly</small>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label">Video link</label>
          <input
            type="text"
            v-model.trim="$v.form.url.$model"
            :class="{ 'is-invalid': $v.form.url.$error }"
            placeholder="https://www.youtube.com/watch?v=xSXQyfnt72o"
            class="form-control"
            @input="setIframe"
          />
          <div class="invalid-feedback">
            <small>Please fill in this field correctly</small>
          </div>
        </div>

        <iframe
          v-if="hasLink"
          width="100%"
          height="400"
          :src="embed"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="rounded-3"
        ></iframe>

        <div class="row">
          <div class="col-12 text-center">
            <span class="text-danger text-center">{{ errorMessage }}</span>
          </div>
        </div>

        <div class="form-group">
          <p>
            Add videos that will be available to students in the <strong>NOVVA Connect APP</strong>. You may enter videos hosted on YouTube or Vimeo.
          </p>
        </div>

        <div class="d-grid gap-2 mt-4">
          <button class="btn btn-primary mt-3" @click="save" :disabled="$v.$invalid || loading || !hasLink">
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Save and continue
          </button>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import AddVideoController from "./AddVideoController";
export default AddVideoController;
</script>

<style></style>
